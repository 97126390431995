import React from 'react'

import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'

import { BrandAmbassador, Recommendations, SEO } from '../components'
import { RainbowContainer } from '../components/nav/styled'
import { View } from '../components/product'
import JsonLd from '../components/seo/jsonld'
import { toBreadcrumbJsonLd, toProductJsonLd } from '../utils/jsonld'

const ProductContainer = styled.div`
  padding-bottom: 60px;
`

const Product = ({ data, path }: any) => {
  const { t } = useTranslation()
  const product = data?.shopifyProduct
  const [firstImage] = product.images

  return (
    <>
      <SEO
        title={product.title}
        description={product.description}
        image={firstImage.originalSrc}
        keywords={[product.productType]}
        path={path}
      />
      <JsonLd>{toBreadcrumbJsonLd(t)(product)}</JsonLd>
      <JsonLd>{toProductJsonLd(product)}</JsonLd>
      <RainbowContainer>{t('bym.worldwide')}</RainbowContainer>
      <ProductContainer>
        <View product={product} />
        <Recommendations recommendations={data.bym.productRecommendations} />
        <BrandAmbassador />
      </ProductContainer>
    </>
  )
}

export default Product

export const query = graphql`
  query ($handle: String!, $shopifyId: ID!) {
    bym {
      productRecommendations(productId: $shopifyId) {
        id
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      availableForSale
      options {
        name
        values
      }
      variants {
        id
        title
        price
        compareAtPrice
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
        image {
          originalSrc
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
